
export function Component(params) {
    return (
        <>
            <h1>Hello World</h1>
            <p>
                Creating a new blog as the old one now refuse to compile itself.
                I hope by using only simple combination of naive React and Notion I do not run into this
                kind of problem again.
                <br />
                I do plan to port some of the old articles to this new blog when I have time.
            </p>
        </>
    )
}