import React from 'react';

function About() {
    return (
        <>
            <h2>Bob Fang's Personal Website</h2>
            <p>I am a software engineer working in Meta London. My main language now is C++ but had previous experience with Python and Golang.</p>
            <p>My day to day job is to optimise the ranking system of Instagram, it is a lot of fun.</p>
        </>
    )
}
export default About;